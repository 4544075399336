<template>
	<div class="memberList">
		<div class="policyCompilationTitle">成员列表</div>
		<div class="top">
			<div style="display: flex;">
				<el-input v-model="keyword" size="small" style="width: 399px;" placeholder="输入用户名称、公司、品牌进行搜索"></el-input>
				<el-button type="primary" icon="el-icon-search" size="small" style="margin-left:5px;" @click="search">搜索</el-button>
			</div>
			<div>
				<el-button type="primary" icon="el-icon-plus" size="small" @click="addMember">添加成员</el-button>
			</div>
		</div>
		<div class="table">
			<el-table :data="managers" border style="width: 100%">
				<el-table-column prop="name" align="center" label="姓名" width="120"></el-table-column>
				<el-table-column prop="mobile" align="center" label="手机号码"></el-table-column>
				<el-table-column prop="email" align="center" label="邮箱" width="160"></el-table-column>
				<el-table-column prop="company" align="center" label="公司" width="120" show-overflow-tooltip></el-table-column>
				<el-table-column prop="brand" align="center" label="品牌" width="120" show-overflow-tooltip></el-table-column>
				<el-table-column prop="roles" align="center" label="身份" width="300">
					<template slot-scope="scope">
						<el-tag v-for="item in scope.row.roles" :key="item.id" style="margin-right: 5px;">{{item.title}}</el-tag>
					</template>
				</el-table-column>
				<el-table-column label="操作" align="center" width="240">
					<template slot-scope="scope">
						<el-button type="primary" size="mini" @click="editHandle(scope.$index,scope.row)">编辑</el-button>
						<el-button type="primary" size="mini" @click="detailHandle(scope.$index,scope.row)">详情</el-button>
						<el-button type="danger" size="mini" @click="deleteHandele(scope.$index,scope.row)">删除</el-button>
					</template>
				</el-table-column>
			</el-table>
			<div class="pages">
				<el-pagination background @current-change="handleCurrentChange" :current-page="1" :page-count="pageCount" layout="total,prev,pager,next,jumper"
				 :total="total">
				</el-pagination>
			</div>
		</div>
	</div>
</template>
<script>
	export default {
		data() {
			return {
				keyword: '',
				managers: [],
				total: 0,
				page: 1,
				limit: 10,
				pageCount: Math.ceil(this.total / 10),
				id: ''
			}
		},
		mounted() {
			this.id = sessionStorage.organizationId;
			this.getMember();
		},
		methods: {
			getMember() {
				this.$http.get(this.URL.adminUrl.managersList + this.id + '/managers', {
					params: {
						page: this.page,
						limit: this.limit,
						title: this.keyword
					}
				}).then((res) => {
					this.managers = res.data.data.managers;
					this.total = res.data.data.meta.total;
				})
			},
			search() {
				this.page = 1;
				this.getMember();
			},
			handleCurrentChange(val) {
				this.page = val;
				this.getMember();
			},
			addMember() {
				this.$router.push('addMember')
			},
			deleteHandele(index, row) {
				this.$confirm('确认删除？', '提示').then(() => {
					this.$http.delete(this.URL.adminUrl.managersList + row.organ_id + '/managers/' + row.id).then(res => {
						this.managers.splice(index, 1);
						this.$message({
							message: '删除成功！',
							type: 'success'
						});
					})
				}).catch(() => {
					this.$message({
						type: 'info',
						message: '已取消删除'
					});
				})

			},
			editHandle(index, row) {
				this.$router.push({
					path: './addMember',
					query: {
						id: row.id
					}
				});
			},
			detailHandle(index, row) {
				this.$router.push({
					path: './memberDetail',
					query: {
						id: row.id
					}
				});
			}
		}
	}
</script>
<style scoped>
	.policyCompilationTitle {
		display: flex;
		align-items: flex-start;
		justify-content: center;
		font-size: 18px;
		height: 40px;
	}

	.top {
		display: flex;
		align-items: center;
		justify-content: space-between;
	}

	.table {
		margin-top: 30px;
	}

	.pages {
		display: flex;
		align-items: center;
		justify-content: center;
		padding: 50px 0;
	}
</style>
